import { Provider } from 'react-redux';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'wini-web-components';
import { Home } from './router/router';
import { store } from './store';
import LoginView from './module/project/login';
import { Ultis } from './Utils';

const checkToken = () => {
  // const token = Ultis.getCookie('accessToken') && localStorage.getItem('listActive');
  const token = Ultis.getCookie('accessToken');
  if (token) {
    return true;
  }
  // localStorage.clear()
  return false;
  // return token !== null && token !== undefined;
};

function App() {
  return <Provider store={store} stabilityCheck="always">
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/login" element={<LoginView />} />
        <Route path="/*" element={checkToken() === true ? <Home /> : <Navigate to={"/login"} replace />} />
      </Routes>
    </BrowserRouter>
  </Provider>
}

export default App;
