import { configureStore } from '@reduxjs/toolkit'
import moduleReducer from './module/reducer'
import projectReducer from './module/project/reducer'
import customerReducer from './module/customer/reducer'
import roleReducer from './module/roles/reducer'

export const store = configureStore({
    reducer: {
        // account: accountReducer,
        module: moduleReducer,
        project: projectReducer,
        customer: customerReducer,
        role: roleReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch