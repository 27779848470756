export default class ConfigApi {
    static pid = "dd757d35dbd245df843b1f54ada656ff";
    // static url = 'http://localhost:3000/api/'
    // static url = 'https://server.wini.vn/api/'
    static url = 'https://apicore.wini.vn/api/'
    static fileUrl = "https://file-mamager.wini.vn/api/";
    static imgUrl = "https://file-mamager.wini.vn/";
    static imgUrlId = "https://file-mamager.wini.vn/api/SystemFile/img/";
    static coreId = "3c932511de9d40a78535604415b1b7ff"
    static tbRole = "Roles"
}

export enum RoleType {
    create = "create",
    read = "read",
    edit = "edit",
    delete = "delete",
}