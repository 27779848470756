import { Button, Text, ToastMessage, Winicon } from "wini-web-components";
import { TextFieldForm } from "../../project-component/component-form";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { CustomerActions } from "../customer/reducer";
import { Ultis } from "../../Utils";

export default function LoginView() {
    const methods = useForm({ shouldFocusError: false })
    const [isShowPass, setIsShowPass] = useState(false)

    const onSubmit = async (data) => {
        console.log(data)
        const res = await CustomerActions.login(data)
        if (res.code === 403) { methods.setError("password", { message: res.message }) }
        else if (res.code !== 200) ToastMessage.errors(res.message)
        else {
            Object.keys(res.data).forEach(key => {
                Ultis.setCookie(key, res.data[key])
            })
            Ultis.setCookie("timeRefresh", Date.now() / 1000 + 9 * 60)
            ToastMessage.success("Login successfully!")
            window.location.replace("/")
        }
    }

    return <div className="row main-layout">
        <img alt='ktx gif' src={require('../../assets/KTX.gif')} style={{ height: "100%", maxWidth: '70%' }} />
        <div className="col" style={{ flex: 1, alignItems: 'center', justifyContent: 'center', gap: '2rem', height: '100%' }} >
            <div className="row" style={{ gap: '0.4rem' }}>
                <img alt='ktx logo' src={require('../../assets/Logo.png')} style={{ width: '3.5rem', height: '4.8rem' }} />
                <Text className="heading-5" style={{ color: "var(--primary-darker-color)" }}>Kiến tạo xanh</Text>
            </div>
            <div className="col" style={{ gap: '2.4rem', alignItems: 'center' }}>
                <Text className="heading-4" style={{ textAlign: 'center' }}>Đăng nhập</Text>
                <div className="col" style={{ gap: '2rem', padding: '0rem 3.2rem', width: '44.6rem' }}>
                    <div className="col" style={{ gap: '1.2rem' }}>
                        <TextFieldForm
                            name="username"
                            errors={methods.formState.errors}
                            register={methods.register}
                            label="Tài khoản"
                            textFieldStyle={{ height: "4.8rem" }}
                            prefix={<Winicon src={"outline/users/user-c-frame"} size={"1.4rem"} />}
                        />
                        <div style={{ height: "0.4rem" }} />
                        <TextFieldForm
                            name="password"
                            errors={methods.formState.errors}
                            register={methods.register}
                            label="Mật khẩu"
                            textFieldStyle={{ height: "4.8rem" }}
                            type={isShowPass ? "text" : "password"}
                            prefix={<Winicon src={"outline/user interface/lock"} size={"1.4rem"} />}
                            onComplete={(ev) => {
                                if (methods.watch("username")?.length) {
                                    ev.target.blur()
                                    onSubmit(methods.getValues())
                                } else ev.target.blur()
                            }}
                            suffix={<button type="button" onClick={() => { setIsShowPass(!isShowPass) }} className="row"><Winicon src={`outline/user interface/${isShowPass ? "view" : "hide"}`} size={"1.4rem"} /></button>}
                        />
                        <NavLink to={"/login"}><Text className="button-text-3" style={{ color: "var(--neutral-text-subtitle-color)" }}>Quên mật khẩu?</Text ></NavLink>
                    </div>
                    <Button
                        label="Đăng nhập"
                        className={`${methods.watch("username")?.length && methods.watch("password")?.length ? 'button-primary' : 'button-disabled'} button-text-1`}
                        style={{ borderRadius: "0.8rem", height: "4.8rem", width: "100%" }}
                        onClick={methods.handleSubmit(onSubmit)}
                    />
                </div>
            </div>
        </div>
    </div >
}