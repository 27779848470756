import { BaseDA } from "../da/baseDA";
import ConfigApi from "../da/configApi";
import { LayerItem, PageItem } from "./dataDa";
import { ColumnItem, DesignTokenItem, MenuItem, RelativeItem, TableItem } from "./tbDa";

export class TableController {
    private module: "table" | "column" | "rel" | "menu" | "page" | "layer" | "designtoken";
    constructor(module: "table" | "column" | "rel" | "menu" | "page" | "layer" | "designtoken") {
        this.module = module
    }

    async getAll() {
        const res = await BaseDA.get(ConfigApi.url + 'setting/getAll', {
            headers: {
                pid: ConfigApi.pid,
                module: this.module,
            },
        })
        return res
    }

    async getByListId(ids: Array<string>) {
        const res = await BaseDA.post(ConfigApi.url + 'setting/getByIds', {
            headers: {
                pid: ConfigApi.pid,
                module: this.module,
            },
            body: { ids: ids }
        })
        return res
    }

    async getListSimple(options?: { page?: number, size?: number, query?: string, returns?: Array<string>, sortby?: { BY: string, DIRECTION?: "ASC" | "DESC" } }) {
        const res = await BaseDA.post(ConfigApi.url + 'setting/getListSimple', {
            headers: {
                pid: ConfigApi.pid,
                module: this.module,
            },
            body: { searchRaw: options?.query ?? "*", page: options?.page ?? 1, size: options?.size ?? 10, returns: options?.returns, sortby: options?.sortby }
        })
        return res
    }

    async add(data: Array<TableItem | ColumnItem | RelativeItem | MenuItem | PageItem | LayerItem | DesignTokenItem>) {
        const res = await BaseDA.post(ConfigApi.url + 'setting/action?action=add', {
            headers: {
                pid: ConfigApi.pid,
                module: this.module
            },
            body: { data: data }
        })
        return res
    }

    async edit(data: Array<TableItem | ColumnItem | RelativeItem | MenuItem | PageItem | LayerItem | DesignTokenItem>) {
        const res = await BaseDA.post(ConfigApi.url + 'setting/action?action=edit', {
            headers: {
                pid: ConfigApi.pid,
                module: this.module
            },
            body: { data: data }
        })
        return res
    }

    async delete(ids: Array<string>) {
        const res = await BaseDA.post(ConfigApi.url + 'setting/action?action=delete', {
            headers: {
                pid: ConfigApi.pid,
                module: this.module
            },
            body: { ids: ids }
        })
        return res
    }
}