import React, { CSSProperties } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import type { Editor, EditorConfig } from '@ckeditor/ckeditor5-core';
import type { EventInfo } from '@ckeditor/ckeditor5-utils';
import './ck-editor.css'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ConfigApi from "../da/configApi";

interface CKEditorProps<TEditor extends Editor> {
    config?: EditorConfig,
    style?: CSSProperties,
    className?: string,
    value?: string,
    disabled?: boolean,
    onReady?: (editor: TEditor) => void;
    onError?: (error: Error, details: any) => void;
    onChange?: (event: EventInfo, editor: TEditor) => void;
    onFocus?: (event: EventInfo, editor: TEditor) => void;
    onBlur?: (event: EventInfo, editor: TEditor) => void;
}

class CustomCKEditor<TEditor extends Editor> extends React.Component<CKEditorProps<TEditor>, {}> {
    render(): React.ReactNode {
        return <div className={`col ck-editor-container ${this.props.className ?? ''}`} style={this.props.style} >
            <CKEditor
                editor={ClassicEditor}
                config={{ ...this.props.config }}
                data={this.props.value}
                disabled={this.props.disabled}
                onReady={this.props.onReady}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                onFocus={this.props.onFocus}
                onError={this.props.onError}
            />
        </div>
    }
}
// MyUploadAdapter.js
class MyUploadAdapter {
    loader: any;
    xhr: XMLHttpRequest | undefined;


    constructor(loader: any) {
        // The file loader instance to use during the upload.
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then((file: any) => new Promise((resolve, reject) => {
                this._initRequest();
                this._initListeners(resolve, reject, file);
                this._sendRequest(file);
            }));
    }

    // Aborts the upload process.
    abort() {
        if (this.xhr) {
            this.xhr.abort();
        }
    }

    // Initializes the XMLHttpRequest object using the URL passed to the constructor.
    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();
        // Note that your request may look different. It is up to you and your editor
        // integration to choose the right communication channel. This example uses
        // a POST request with JSON as a data structure but your configuration
        // could be different.
        xhr.open('POST', ConfigApi.fileUrl + 'SystemFileAuth/Upload', true);
        // xhr.setRequestHeader("Authorization", 'Bearer ' + localStorage.getItem("token"));
        xhr.responseType = 'json';
    }

    // Initializes XMLHttpRequest listeners.
    _initListeners(resolve: { (value: unknown): void; (arg0: { default: string; }): void; }, reject: { (reason?: any): void; (arg0: string | undefined): any; }, file: { name: any; }) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${file.name}.`;

        xhr?.addEventListener('error', () => reject(genericErrorText));
        xhr?.addEventListener('abort', () => reject());
        xhr?.addEventListener('load', () => {
            const response = xhr.response;

            // This example assumes the XHR server's "response" object will come with
            // an "error" which has its own "message" that can be passed to reject()
            // in the upload promise.
            //
            // Your integration may handle upload errors in a different way so make sure
            // it is done properly. The reject() function must be called when the upload fails.
            if (!response || response.error) {
                return reject(response && response.error ? response.error.message : genericErrorText);
            }

            // If the upload is successful, resolve the upload promise with an object containing
            // at least the "default" URL, pointing to the image on the server.
            // This URL will be used to display the image in the content. Learn more in the
            // UploadAdapter#upload documentation.
            console.log('11111111', response)
            resolve({
                default: ConfigApi.imgUrlId + response.data[0].id
            });
        });

        // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
        // properties which are used e.g. to display the upload progress bar in the editor
        // user interface.
        if (xhr?.upload) {
            xhr.upload.addEventListener('progress', (evt: { lengthComputable: any; total: any; loaded: any; }) => {
                if (evt.lengthComputable) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            });
        }
    }

    // Prepares the data and sends the request.
    _sendRequest(file: string | Blob) {
        // Prepare the form data.
        const data = new FormData();

        data.append('files', file);

        // Important note: This is the right place to implement security mechanisms
        // like authentication and CSRF protection. For instance, you can use
        // XMLHttpRequest.setRequestHeader() to set the request headers containing
        // the CSRF token generated earlier by your application.

        // Send the request.
        this.xhr?.send(data);
    }
}

export default function DefaultCkEditor({ className, style, value, onBlur, onChange, onFocus }:
    { className?: string, style?: CSSProperties, value?: string, onBlur?: (vl?: string) => void, onChange?: (vl?: string) => void, onFocus?: (event: EventInfo, editor: { getData: () => any }) => void }) {
    return <CustomCKEditor
        className={className}
        config={{
            htmlSupport: {
                allow: [
                    {
                        name: /.*/,
                        attributes: true,
                        classes: true,
                        styles: true
                    }
                ]
            },
            toolbar: [
                "heading",
                "|",
                "bold",
                "italic",
                "link",
                'fontSize',
                "bulletedList",
                "numberedList",
                "|",
                "mediaEmbed",
                '|', 'imageUpload',
                '|',
                "undo",
                "redo",
            ],
            extraPlugins: [function (editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => MyUploadAdapter; }; }; }) {
                editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
                    return new MyUploadAdapter(loader);
                };
            }],
            mediaEmbed: {
                previewsInData: true,
                providers: [
                    {
                        name: "youtube",
                        url: /^https:\/\/www\.youtube\.com\/watch\?v=([\w-]+)/,
                        html: (match) => {
                            const id = match[1];
                            return (
                                '<div style="position: relative; padding-bottom: 56.25%; height: 0;">' +
                                `<iframe src="https://www.youtube.com/embed/${id}" ` +
                                'style="position: absolute; width: 100%; height: 100%; left: 0;" ' +
                                'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>' +
                                "</div>"
                            );
                        },
                    },
                ],
            },
            htmlEmbed: {
                showPreviews: true
            }
        }}
        style={style}
        value={value}
        onFocus={onFocus}
        onChange={(_: any, editor: { getData: () => any }) => {
            if (onChange) onChange(editor.getData())
        }}
        onBlur={(_: any, editor: { getData: () => any }) => {
            if (onBlur) onBlur(editor.getData())
        }}
    />
}