import { Dispatch, PayloadAction, UnknownAction, createSlice } from '@reduxjs/toolkit'
import { ProjectItem } from './da'
import { BaseDA } from '../../da/baseDA'
import ConfigApi from '../../da/configApi'
import { ToastMessage } from 'wini-web-components'

interface ProjectSimpleResponse {
    data?: ProjectItem,
    onLoading?: boolean,
    type?: string
}

const initState: ProjectSimpleResponse = {
    data: undefined,
    onLoading: false
}

export const projectSlice = createSlice({
    name: 'project',
    initialState: initState,
    reducers: {
        handleActions: (state, action: PayloadAction<any>) => {
            switch (action.payload.type) {
                case 'GETINFOR':
                    state.data = action.payload.data
                    break;
                default:
                    break;
            }
            state.onLoading = false
        },
        onFetching: (state) => {
            state.onLoading = true
        },
    },
})

const { handleActions, onFetching } = projectSlice.actions

export default projectSlice.reducer

export class ProjectActions {
    static getInfor = async (dispatch: Dispatch<UnknownAction>) => {
        dispatch(onFetching())
        const res = await BaseDA.post(ConfigApi.url + 'wini/getByIds', {
            headers: { module: 'Project' },
            body: { ids: [ConfigApi.pid] }
        })
        if (res.code === 200) {
            dispatch(handleActions({
                type: 'GETINFOR',
                data: res.data[0],
            }))
        }
    }

    static edit = async (dispatch: Dispatch<UnknownAction>, item: ProjectItem) => {
        dispatch(onFetching())
        const res = await BaseDA.post(ConfigApi.url + 'wini/action?action=edit', {
            headers: { module: 'Project' },
            body: { data: [item] }
        })
        if (res.code === 200) {
            ToastMessage.success("Change project information successfully!")
            dispatch(handleActions({
                type: 'GETINFOR',
                data: item,
            }))
        }
        else ToastMessage.errors(res.message)
    }
}