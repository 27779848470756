import { Dispatch, PayloadAction, UnknownAction, createSlice } from '@reduxjs/toolkit'
import ConfigApi from '../../da/configApi'
import { RoleItem } from './da'
import { DataController } from '../dataController'

interface RoleSimpleResponse {
    data?: RoleItem,
    onLoading?: boolean,
    type?: string
}

const initState: RoleSimpleResponse = {
    data: undefined,
    onLoading: true
}

export const roleSlice = createSlice({
    name: 'role',
    initialState: initState,
    reducers: {
        handleActions: (state, action: PayloadAction<any>) => {
            switch (action.payload.type) {
                case 'GETDATA':
                    state.data = action.payload.data
                    break;
                default:
                    break;
            }
            state.onLoading = false
        },
        onFetching: (state) => {
            state.onLoading = true
        },
    },
})

const { handleActions, onFetching } = roleSlice.actions

export default roleSlice.reducer

export class RoleActions {
    static getById = async (dispatch: Dispatch<UnknownAction>, id: string) => {
        dispatch(onFetching())
        const _controller = new DataController(ConfigApi.tbRole)
        const res = await _controller.getById(id)
        if (res.code === 200) {
            dispatch(handleActions({
                type: 'GETDATA',
                data: res.data,
            }))
        }
    }
}