import styles from './index.module.css'
import { forwardRef, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { TableController } from "../module/tbController"
import { Button, closePopup, Popup, showPopup, Text, TextField, ToastMessage, Winicon } from "wini-web-components"
import { randomGID, Ultis } from "../Utils"
import { Select1Form, TextAreaForm, TextFieldForm } from "../project-component/component-form"
import { useForm } from "react-hook-form"
import { ModuleActions } from '../module/reducer'
import { ToolType } from '../module/tbDa'
import { ProjectActions } from '../module/project/reducer'
import { RoleType } from '../da/configApi'

export default function ActionSidebar() {
    const [listModule, setListM] = useState([])
    const selectedM = useSelector((store) => store.module.data)
    const _role = useSelector((store) => store.role.data)
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const _pageId = searchParams.get("pageid")
    const _menuController = new TableController("menu")
    const [onEdit, setOnEdit] = useState()
    const popupRef = useRef()
    const dispatch = useDispatch()

    useEffect(() => {
        if (_role) {
            ProjectActions.getInfor(dispatch)
            _menuController.getAll().then(res => {
                if (res.code === 200 && res.data) {
                    setListM(sortMenu(res.data))
                } else navigate('/')
            })
        }
    }, [_role])

    const sortMenu = (_list) => {
        return _list.sort((a, b) => {
            const _pA = a.ParentId ? _list.find(el => el.Id === a.ParentId) : undefined
            const _pB = a.ParentId === b.ParentId ? _pA : b.ParentId ? _list.find(el => el.Id === b.ParentId) : undefined
            a.parentId = _pA ? Ultis.toSlug(_pA.Name) : undefined
            b.parentId = _pB ? Ultis.toSlug(_pB.Name) : undefined
            if (a.ParentId && !b.ParentId) return -1
            else if (!a.ParentId && b.ParentId) return 1
            else if (a.ParentId === b.ParentId) return a.Sort - b.Sort
            else return _pA.Sort - _pB.Sort
        })
    }

    useEffect(() => {
        if (listModule.length) {
            const _m = listModule.find(e => _pageId === e.PageId || _pageId === e.TableId)
            if (_m) ModuleActions.setSelectedM(dispatch, _m)
        }
    }, [_pageId, listModule.length, location.pathname])

    const handleRightClick = (ev, item) => {
        const _sameLevel = listModule.filter(e => e.ParentId === item.ParentId && e.id !== "admin" && e.parentId !== "admin")
        const _index = _sameLevel.findIndex(e => e.Id === item.Id)
        switch (ev.type) {
            case 'contextmenu':
                ev.preventDefault()
                showPopup({
                    ref: popupRef,
                    clickOverlayClosePopup: true,
                    style: { left: ev.pageX, top: ev.pageY, borderRadius: '0.4rem' },
                    content: <div className="col popup-actions" style={{ maxHeight: 'fit-content' }}>
                        {!item.ParentId && !item.TableId && _role?.ListActive?.includes(RoleType.create) ? <button type="button"
                            onClick={() => {
                                closePopup(popupRef)
                                addMenuItem(item.Id)
                            }}>
                            <Text className="button-text-3">Create child module</Text>
                        </button> : null}
                        {_index > 0 ? <button type="button" onClick={() => {
                            const _sortList = [item, ..._sameLevel.filter(e => e.Id !== item.Id)]
                            updateSort(_sortList)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Move to top</Text>
                        </button> : null}
                        {_index > 0 ? <button type="button" onClick={() => {
                            const _sortList = [..._sameLevel.slice(0, _index - 1), item, ..._sameLevel.slice(_index - 1).filter(e => e.Id !== item.Id)]
                            updateSort(_sortList)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Move up</Text>
                        </button> : null}
                        {(_index + 1) < _sameLevel.length ? <button type="button" onClick={() => {
                            const _sortList = [..._sameLevel.slice(0, _index), _sameLevel[_index + 1], item, ..._sameLevel.slice(_index + 2)]
                            updateSort(_sortList)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Move down</Text>
                        </button> : null}
                        {(_index + 1) < _sameLevel.length ? <button type="button" onClick={() => {
                            const _sortList = [..._sameLevel.filter(e => e.Id !== item.Id), item]
                            updateSort(_sortList)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Move to bottom</Text>
                        </button> : null}
                        <button type="button" onClick={() => {
                            setOnEdit({ id: item.Id, target: ev.target.querySelector("input") })
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Change name</Text>
                        </button>
                        {_role?.ListActive?.includes(RoleType.delete) && <button type="button" onClick={() => {
                            deleteMennuItem(item)
                            closePopup(popupRef)
                        }}>
                            <Text className="button-text-3">Delete</Text>
                        </button>}
                    </div >
                })
                break;
            default:
                break;
        }
    }

    const updateSort = (sortList = []) => {
        const _sortList = sortList.map((e, i) => {
            let _item = { ...e, Sort: i }
            delete _item.id
            delete _item.name
            delete _item.parentId
            return _item
        })
        _menuController.edit(_sortList)
        setListM(sortMenu(listModule.map(e => {
            const _item = _sortList.find(el => el.Id === e.Id)
            return { ...e, ..._item }
        })))
    }

    const addMenuItem = (parentId) => {
        showPopup({
            ref: popupRef,
            heading: <div className="heading-7 popup-header">Tạo mới module</div>,
            hideButtonClose: true,
            content: <AddModulePopup
                ref={popupRef}
                parentId={parentId}
                onSubmit={async (_newItem) => {
                    const _sameLevel = listModule.filter(e => e.ParentId === parentId && e.id !== "admin" && e.parentId !== "admin")
                    _newItem.Sort = _sameLevel.length
                    const res = await _menuController.add([
                        ..._sameLevel.map((e, i) => {
                            let _item = { ...e, Sort: i }
                            delete _item.id
                            delete _item.name
                            delete _item.parentId
                            return _item
                        }),
                        _newItem
                    ])
                    if (res.code !== 200) return
                    ToastMessage.success('Create new module successfully!')
                    _newItem.id = _newItem.Name
                    _newItem.parentId = _newItem.ParentId ? Ultis.toSlug(listModule.find(e => e.Id === _newItem.ParentId)?.Name) : undefined
                    _newItem.name = _newItem.Name
                    setListM([...listModule, _newItem])
                    setTimeout(() => {
                        if (_newItem.PageId === ToolType.Manager) {
                            navigate(`/manager/${_newItem.parentId ? `${_newItem.parentId}/${_newItem.id}` : _newItem.id}?pageid=${_newItem.TableId}`)
                        } else {
                            navigate(`/page/${_newItem.parentId ? `${_newItem.parentId}/${_newItem.id}` : _newItem.id}?pageid=${_newItem.PageId}`)
                        }
                    }, 800)
                }}
            />
        })
    }

    const deleteMennuItem = (item) => {
        const children = listModule.filter(e => e.ParentId === item.Id).map(e => e.Id)
        _menuController.delete([item.Id, ...children]).then(res => {
            if (res.code === 200) {
                const _sameLevel = listModule.filter(e => e.ParentId === item.ParentId)
                let _index = _sameLevel.findIndex(e => e.Id === item.Id)
                if (_index === 0) _index = 1
                else _index = _index - 1
                if (_index < 0) _index += 2
                let _selectedM = _sameLevel[_index]
                const _newMenu = listModule.filter((e) => e.Id !== item.Id && children.every(id => id !== e.Id))
                if (!_selectedM?.TableId) _selectedM = _newMenu.find(e => e.PageId)
                if (_selectedM.PageId === ToolType.Manager) {
                    navigate(`/manager/${_selectedM.parentId ? `${_selectedM.parentId}/${_selectedM.id}` : _selectedM.id}?pageid=${_selectedM.TableId}`)
                } else {
                    navigate(`/page/${_selectedM.parentId ? `${_selectedM.parentId}/${_selectedM.id}` : _selectedM.id}?pageid=${_selectedM.PageId}`)
                }
                setListM(listModule.filter(e => e.Id !== item.Id))
            } else {
                ToastMessage.errors(res.message)
            }
        })
    }

    useEffect(() => {
        if (onEdit?.target) onEdit.target.focus()
    }, [onEdit])

    const renderModuleTile = (item) => {
        if (!item.parentId) var children = listModule.filter(e => e.ParentId === item.Id)
        if (!_role || ![item.TableId, ...(children ?? []).map(e => e.TableId)].some((id) => _role.TableId.includes(id))) return null
        // 
        const isSelected = selectedM && (selectedM.Id === item.Id || selectedM.ParentId === item.Id)
        if (children && item.isOpen == undefined) var _isOpen = isSelected
        return <div key={item.Id} className="col">
            <button type="button" className={`row ${styles['module-tile']} ${!children?.length && isSelected ? styles['selected'] : ''}`}
                onContextMenu={item.Id && _role?.ListActive?.includes(RoleType.edit) ? (ev) => { handleRightClick(ev, item) } : undefined}
                onClick={() => {
                    if (item.TableId) {
                        navigate(`/manager/${item.ParentId ? `${Ultis.toSlug(listModule.find(e => e.Id === item.ParentId)?.Name ?? "")}/${Ultis.toSlug(item.Name)}` : Ultis.toSlug(item.Name)}?pageid=${item.TableId}`)
                    } else {
                        setListM(listModule.map(e => {
                            if (e.Id === item.Id) e.isOpen = !(item.isOpen ?? _isOpen)
                            return e
                        }))
                    }
                }}>
                {children?.length ? <Winicon src={item.isOpen || _isOpen ? "fill/arrows/triangle-down" : "fill/arrows/triangle-right"} size={'1.2rem'} /> : item.Icon ? <Winicon src={item.Icon} size={"1.4rem"} /> : <div className={styles["prefix"]} />}
                <TextField
                    className="label-3"
                    style={{ borderColor: item.Id !== onEdit?.id ? "transparent" : undefined }}
                    defaultValue={item.Name}
                    autoFocus
                    disabled={item.Id !== onEdit?.id}
                    onBlur={async (ev) => {
                        const _newValue = ev.target.value.trim()
                        if (_newValue.length) {
                            let _updateItem = { ...item, Name: _newValue }
                            delete _updateItem.isOpen
                            _menuController.edit([_updateItem]).then(res => {
                                if (res.code === 200) {
                                    setListM(listModule.map(e => {
                                        if (e.Id === item.Id) return _updateItem
                                        else return e
                                    }))
                                    if (_updateItem.Id === selectedM?.Id) ModuleActions.setSelectedM(dispatch, { ...selectedM, ..._updateItem })
                                } else {
                                    ev.target.value = item.Name
                                    ToastMessage.errors(res.message)
                                }
                            })
                        } else ev.target.value = item.Name
                        if (onEdit) setOnEdit(undefined)
                    }}
                />
                {children?.length && _role?.ListActive?.includes(RoleType.create) ? <div className='row' style={{ width: '1.6rem', height: '1.6rem' }} onClick={() => { addMenuItem(item.Id) }}><Winicon src={"outline/user interface/e-add"} size={"1.4rem"} /></div> : undefined}
            </button>
            {item.isOpen || _isOpen ? children.map(e => renderModuleTile(e)) : undefined}
        </div>
    }

    return <div className={`col ${styles['sidebar']}`}>
        <Popup ref={popupRef} />
        <div className='col'>
            {Object.keys(RoleType).every(e => _role?.ListActive?.includes(RoleType[e])) && <NavLink to="/setting" className={`row ${styles['module-tile']} ${window.location.pathname.startsWith("/setting") ? styles['selected'] : ""}`} >
                <Winicon src='outline/user interface/settings-gear' size={'2rem'} />
                <Text className='label-3'>Setting</Text>
            </NavLink>}
        </div>
        <div className={`col ${styles['module-list-container']}`}>
            {listModule.filter(e => !e.ParentId).map(e => renderModuleTile(e))}
        </div>
        {window.location.pathname.startsWith("/home") ? null : <div className='col'>
            {_role?.ListActive?.includes(RoleType.create) && <Button
                label='Add Module'
                className='button-text-2 button-neutral'
                style={{ width: '100%', backgroundColor: "transparent", height: "4rem" }}
                prefix={<Winicon src={"outline/user interface/e-add"} />}
                onClick={() => { addMenuItem() }}
            />}
            <Button
                label='Logout'
                className='button-text-2'
                style={{ width: '100%', backgroundColor: "transparent", color: "var(--neutral-text-subtitle-color)", height: "4rem", gap: "1.2rem" }}
                prefix={<Winicon src={"outline/arrows/log-out"} />}
                onClick={() => {
                    Ultis.clearCookie()
                    window.location.replace("/login")
                }}
            />
        </div>}
    </div>
}

const AddModulePopup = forwardRef(function AddModulePopup(data, ref) {
    const methods = useForm({ shouldFocusError: false, defaultValues: { PageId: ToolType.Manager } })
    const _tbController = new TableController("table")
    const _pageController = new TableController("page")
    const [tables, setTables] = useState([])
    const [pages, setPages] = useState([])
    // const iconNames = [...Object.keys(solidIcon), ...Object.keys(brandIcon), ...Object.keys(regularIcon)].filter(key => key.startsWith('fa')).map(icon => Ultis.convertToKebabCase(icon.replace('fa', '')))

    const onSubmit = async (ev) => {
        const _newItem = {
            Id: randomGID(),
            ...ev,
            DateCreated: Date.now(),
            ParentId: data.parentId,
        }
        data.onSubmit(_newItem)
        closePopup(ref)
    }

    useEffect(() => {
        _pageController.getAll().then(res => {
            if (res.code === 200) setPages(res.data)
        })
    }, [])

    useEffect(() => {
        if (!tables.length && methods.getValues("PageId") === ToolType.Manager) {
            _tbController.getAll().then(res => {
                if (res.code === 200) {
                    setTables(res.data)
                } else {
                    ToastMessage.errors(res.message)
                }
            })
        }
    }, [methods.watch("PageId")])

    return <div className="col" style={{ flex: 1 }}>
        <div className="col" style={{ padding: '1.6rem', gap: '1.6rem', overflow: 'hidden auto', flex: 1, width: '56rem' }}>
            <div className="row" style={{ gap: '1.2rem' }}>
                <TextFieldForm
                    name={"Icon"}
                    style={{ width: '12rem' }}
                    placeholder={"Icon"}
                    register={methods.register}
                    errors={methods.formState.errors}
                />
                <TextFieldForm
                    required
                    style={{ flex: 1 }}
                    name={"Name"}
                    placeholder={"Module name"}
                    register={methods.register}
                    errors={methods.formState.errors}
                />
            </div>
            <Select1Form
                disabled
                name={"PageId"}
                placeholder={"Choose page"}
                control={methods.control}
                errors={methods.formState.errors}
                hideSearch
                options={[{ id: ToolType.Manager, name: ToolType.Manager }, ...pages.map(e => {
                    return {
                        id: e.Id,
                        name: e.Name
                    }
                })]}
            />
            {methods.watch("PageId") === ToolType.Manager && <Select1Form
                required={data.parentId != undefined}
                name={"TableId"}
                placeholder={"Choose table"}
                control={methods.control}
                errors={methods.formState.errors}
                options={tables.map(e => {
                    return {
                        id: e.Id,
                        name: e.Name
                    }
                })}
            />}
            <TextAreaForm
                name={"Description"}
                placeholder={"Write some thing about this module..."}
                register={methods.register}
                errors={methods.formState.errors}
            />
        </div>
        <div className="row popup-footer">
            <Text className="button-text-3" onClick={() => { closePopup(ref) }}>Cancel</Text>
            <div style={{ flex: 1 }} />
            <button type="button"
                className={`row ${(methods.watch('Name')?.length && (!data.parentId || methods.watch('PageId')) ? 'button-primary' : 'button-disabled')}`}
                onClick={methods.handleSubmit(onSubmit)}
            >
                <Text className="button-text-3">Submit</Text>
            </button>
        </div>
    </div>
})