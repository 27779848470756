import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Text } from "wini-web-components";
import { CKEditorForm, ImportFileForm, TextFieldForm } from "../../project-component/component-form";
import { BaseDA } from "../../da/baseDA";
import { useDispatch, useSelector } from "react-redux";
import { ProjectActions } from "./reducer";

export default function SettingProject() {
    const projectData = useSelector((store) => store.project.data)
    const methods = useForm({ shouldFocusError: false })
    const dispatch = useDispatch()

    const onSubmit = async (ev) => {
        if (typeof ev.LogoId !== "string") {
             const uploadRes = await BaseDA.uploadFiles([ev.LogoId])
            if (uploadRes) ev.LogoId = uploadRes[0].id
        }
        ProjectActions.edit(dispatch, ev)
    }

    useEffect(() => {
        if (projectData) {
            Object.keys(projectData).forEach(k => {
                methods.setValue(k, projectData[k])
            })
        }
    }, [projectData])

    return <div className="col">
        <Text className="heading-5" style={{ width: "100%", padding: "1.6rem 2.4rem" }}>Edit project information</Text>
        <div className="col" style={{ gap: '2.4rem', flex: 1, overflow: "hidden auto", width: "100%", alignItems: 'center' }}>
            <TextFieldForm
                className={'row'}
                required
                style={{ width: '68rem', gap: '0.4rem' }}
                labelStyle={{ width: "16rem" }}
                errors={methods.formState.errors}
                register={methods.register}
                name={'Name'}
                label={'Name'}
            />
            <TextFieldForm
                className={'row'}
                required
                style={{ width: '68rem', gap: '0.4rem' }}
                labelStyle={{ width: "16rem" }}
                errors={methods.formState.errors}
                register={methods.register}
                name={'Sologan'}
                label={'Sologan'}
            />
            <ImportFileForm
                required
                control={methods.control}
                className={'row'}
                style={{ width: '68rem', gap: '0.4rem' }}
                labelStyle={{ width: "16rem" }}
                errors={methods.formState.errors}
                name="LogoId"
                label="Logo"
                title="Upload logo"
                direction="column"
                allowType={["image/svg+xml"]}
            />
            <TextFieldForm
                className={'row'}
                required
                style={{ width: '68rem', gap: '0.4rem' }}
                labelStyle={{ width: "16rem" }}
                errors={methods.formState.errors}
                register={methods.register}
                name={'Title'}
                label={'Logo title'}
            />
            <TextFieldForm
                className={'row'}
                required
                style={{ width: '68rem', gap: '0.4rem' }}
                labelStyle={{ width: "16rem" }}
                errors={methods.formState.errors}
                register={methods.register}
                name={'Subtitle'}
                label={'Logo subtitle'}
            />
            <CKEditorForm
                className={'row'}
                required
                labelStyle={{ width: "16rem" }}
                style={{ width: '68rem', gap: '0.4rem' }}
                errors={methods.formState.errors}
                control={methods.control}
                name={'Description'}
                label={'Description'}
                placeholder={'Write something abpout this project: "This is a project,...'}
            />
        </div>
        <div className={'row'} style={{ justifyContent: 'end', padding: "1.6rem 2.4rem", borderTop: "var(--neutral-bolder-border-color)", width: "100%" }}>
            <Button
                label="Save"
                onClick={methods.handleSubmit(onSubmit)}
                className={methods.watch('Name') && methods.watch('Description') ? 'button-primary' : 'button-disabled'}
                style={{padding: "0 1.6rem"}}
            />
        </div>
    </div>
}