import { useEffect, useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import ActionSidebar from "./menu"
import Header from './header'
import ModuleListView from "../module/manager/view"
import { TableController } from "../module/tbController"
import { Ultis } from "../Utils"
import SettingProject from "../module/project/setting"
import { CustomerActions } from "../module/customer/reducer"
import { RoleActions } from "../module/roles/reducer"


export const Home = () => {
    const user = useSelector((store) => store.customer.data)
    const _role = useSelector((store) => store.role.data)
    const dispatch = useDispatch()

    useEffect(() => {
        CustomerActions.getInfor(dispatch)
    }, [])

    useEffect(() => {
        if (user) RoleActions.getById(dispatch, user.RolesId)
    }, [user])

    return <div className="row main-layout">
        {user && _role && <Routes>
            {/* <Route
                path={'/pages/:pName'}
                element={<SettingPageView />}
                exact
            />
            <Route
                path={'/home'}
                element={<MainRoute body={<ListProject />} />}
                exact
            />
            <Route
                path={'/admin'}
                element={<MainRoute body={<ListTable />} />}
                exact
            /> */}
            <Route
                path={'/setting'}
                element={<MainRoute body={<SettingProject />} />}
                exact
            />
            <Route
                path={'/'}
                element={<DefaultRoute />}
                exact
            />
            <Route
                path={'/manager/:pModuleName?/:moduleName'}
                element={<MainRoute body={<ManagerView />} />}
                exact
            />
            {/* <Route
                path={'/page/:pageName'}
                element={<MainRoute body={<PageView />} />}
                exact
            /> */}
        </Routes>}
    </div>
}

const MainRoute = ({ body }) => {
    return <>
        <Header />
        <ActionSidebar />
        {body}
    </>
}

const ManagerView = () => {
    const onLoading = useSelector((store) => store.module.onLoading)
    const _role = useSelector((store) => store.role.data)
    const searchParams = new URLSearchParams(window.location.search)
    const _pageId = searchParams.get("pageid")
    const [table, setTable] = useState()

    useEffect(() => {
        if (_role.TableId.includes(_pageId)) {
            const _tbController = new TableController("table")
            _tbController.getByListId([_pageId]).then(res => {
                if (res.code === 200) setTable(res.data[0])
                else setTable(undefined)
            })
        } else window.location.replace("/")
    }, [_pageId, _role])

    return (onLoading || !table) ? <div /> : <ModuleListView module={table.Name} />
}

const DefaultRoute = () => {
    const _role = useSelector((store) => store.role.data)
    const navigate = useNavigate()

    useEffect(() => {
        const _menuController = new TableController("menu")
        _menuController.getAll().then(res => {
            if (res.code === 200 && res.data.length) {
                const _page = res.data.sort((a, b) => a.Sort - b.Sort).find(e => e.TableId?.length && _role.TableId.includes(e.TableId))
                navigate(`/manager/${Ultis.toSlug(_page?.Name)}?pageid=${_page.TableId}`)
            }
        })
    }, [])

    return <div />
}