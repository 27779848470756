import { Button, TextField, Winicon } from 'wini-web-components'
import styles from './index.module.css'
import { useSelector } from 'react-redux'

export default function Header() {
    const user = useSelector((store) => store.customer.data)

    return <div className={`row ${styles['header']}`}>
        <button type='button' className='row icon-button24'>
            <Winicon src="fill/user interface/menu-6" size={'1.6rem'} />
        </button>
        <Button
            prefix={<Winicon src={"fill/user interface/c-add"} size={"1.4rem"} color='var(--primary-main-color)' />}
            label='Button'
            style={{ border: 'var(--neutral-bolder-border-color)', backgroundColor: "#fff", width: '9.4rem', borderRadius: '2.4rem' }}
        />
        <TextField
            prefix={<Winicon src={"fill/development/zoom"} size={"1.4rem"} />}
            className={`placeholder-2 ${styles['header-search']}`}
            placeholder='Search'
        />
        <div style={{ flex: 1 }} />
        <button type='button' className='row icon-button24'>
            <Winicon src='fill/user interface/bell' size={'1.6rem'} />
        </button>
        <button type='button' className='row icon-button24'>
            <Winicon src="fill/user interface/mail" size={'1.8rem'} />
        </button>
        <button type='button' className='row icon-button32'>
            <Winicon src='color/flags/vietnam' size={'2.8rem'} />
        </button>
        <button type='button' className='row'>
            <img src={user?.AvatarUrl ?? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU3FcmHH1HtWFPQqC9Z-IK4JdvSWkvswcDfA&s"} alt='' style={{ borderRadius: "50%", width: '3.2rem', height: '3.2rem' }} />
        </button>
    </div>
}