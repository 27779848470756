import { forwardRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Switch, Text } from "wini-web-components";
import { Ultis } from "../../Utils";
import { FEDataType } from "../tbDa";

const PopupSearchIn = forwardRef(function PopupSearchIn(data, ref) {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const [modifyData, setNewData] = useState(data)
    const navigate = useNavigate()
    var searchin = "searchin";

    const onChangeParam = (item) => {
        const params = new URLSearchParams();

        for (const [key, value] of searchParams) {
            const _col = modifyData.cols.find(e => e.Name === key.replace('_min', "").replace('_max', ""));
            switch (_col?.DataType) {
                case FEDataType.NUMBER:
                    params.append(key, typeof value === 'string' ? parseFloat(value) : value)
                    break;
                case FEDataType.DATE:
                    params.append(key, Ultis.stringToDate(value).getTime())
                    break;
                case FEDataType.DATETIME:
                    params.append(key, Ultis.stringToDate(value, 'dd/mm/yyyy hh:mm:ss').getTime())
                    break;
                case FEDataType.MONEY:
                    params.append(key, parseInt(value.replaceAll(',', '')))
                    break;
                default:
                    if (key === searchin) {
                        var seaval = value.split(',');
                        if (!item.Setting.isChecked) {
                            seaval.splice(seaval.indexOf(item.Setting.Title), 1);
                            if (seaval.length === 0)
                                break;
                        }
                        else { seaval.push(item.Name); }
                        params.append(searchin, seaval.join(','));
                    } else
                        params.append(key, value)
                    break;
            }

        }

        if (!searchParams.get(searchin)) {
            params.append(searchin, item.Name);
        }

        navigate('?' + params.toString());
    }

    useEffect(() => {
        for (const [key, value] of searchParams) {
            if (key === searchin) {
                const valueList = value.split(',')
                if (valueList) {
                    modifyData.cols.forEach(element => {
                        if (valueList.find((e) => e == element.Setting.Title)) {
                            element.Setting.isChecked = true
                        }
                    });
                }
            }
        }
        setNewData(modifyData)
    }, [modifyData, searchParams])

    return <form className="col" style={{ flex: 1, width: '100%', height: '100%' }}>
        <div className='col popup-actions' style={{ borderRadius: '0.8rem', flex: 1, backgroundColor: '#fff', overflow: 'hidden auto', }}>
            {modifyData.cols.map((e) => {
                return <div className='row' key={e.Id} style={{ justifyContent: 'space-between', padding: '0.6rem 1.2rem', gap: '0.8rem' }}>
                    <Text className="label-3">{e.Setting.Title}</Text>
                    <Switch
                        name={e.Name}
                        value={e.Setting.isChecked}
                        label={e.Title} key={e.Id} size={16} onChange={(ev) => {
                            e.Setting.isChecked = ev
                            onChangeParam(e)
                        }} />
                </div>
            })}
        </div>
    </form>
})

export default PopupSearchIn