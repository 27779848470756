import { forwardRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Text, closePopup } from "wini-web-components";
import { RangeForm, Select1Form, SelectMultipleForm } from "../../project-component/component-form";
import { Ultis } from "../../Utils";
import { ComponentType, FEDataType } from "../tbDa";
import { RenderComponentByType } from "../config";
import { DataController } from "../dataController";

const PopupFormFilter = forwardRef(function PopupFormFilter(data, ref) {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const methodOptions = useForm({ shouldFocusError: false })
    const methods = useForm({ shouldFocusError: false })
    const navigate = useNavigate()
    const [_cols, setCols] = useState([])
    const [_rels, setRels] = useState([])

    const onSubmit = (ev) => {
        closePopup(ref)
        const params = new URLSearchParams();
        for (const key in ev) {
            if (ev[key] !== undefined && ev[key] !== null) {
                let _col = undefined
                if (key.endsWith("Id")) _col = _rels.find(e => e.Column === key)
                if (typeof ev[key] !== "string" || ev[key].length || _col) {
                    _col ??= _cols.find(e => e.Name === key.replace('_min', "").replace('_max', ""));
                    if (_col) {
                        switch (_col?.DataType) {
                            case FEDataType.NUMBER:
                                params.append(key, typeof ev[key] === 'string' ? parseFloat(ev[key]) : ev[key])
                                break;
                            case FEDataType.DATE:
                                params.append(key, Ultis.stringToDate(ev[key]).getTime())
                                break;
                            case FEDataType.DATETIME:
                                params.append(key, Ultis.stringToDate(ev[key], 'dd/mm/yyyy hh:mm:ss').getTime())
                                break;
                            case FEDataType.MONEY:
                                params.append(key, parseInt(ev[key].replaceAll(',', '')))
                                break;
                            default:
                                if (_col.Column) {
                                    params.append(key, typeof ev[key] === "string" ? ev[key] : ev[key].join(","))
                                } else {
                                    params.append(key, ev[key])
                                }
                                break;
                        }
                    } else params.append(key, ev[key])
                }
            }
        }
        navigate('?' + params.toString());
    }

    useEffect(() => {
        const _setCols = data.cols.filter(e => e.Name !== "Id" && !e.Query && ![FEDataType.FILE, FEDataType.GID, FEDataType.HTML, FEDataType.PASSWORD, FEDataType.STRING].includes(e.DataType))
        for (const [key, value] of searchParams) {
            let _col = undefined
            if (key.endsWith("Id")) _col = data.rels.find(e => e.Column === key)
            _col ??= _setCols.find(e => e.Name === key.replace('_min', "").replace('_max', ""))
            if (_col) {
                switch (_col?.DataType) {
                    case FEDataType.NUMBER:
                        methods.setValue(key, typeof value === 'string' ? parseFloat(value) : value)
                        break;
                    case FEDataType.DATE:
                        methods.setValue(key, Ultis.datetoString(new Date(typeof value === 'string' ? parseInt(value) : value)))
                        break;
                    case FEDataType.DATETIME:
                        methods.setValue(key, Ultis.datetoString(new Date(typeof value === 'string' ? parseInt(value) : value), 'dd/mm/yyyy hh:mm:ss'))
                        break;
                    case FEDataType.MONEY:
                        methods.setValue(key, Ultis.money(value))
                        break;
                    case FEDataType.BOOLEAN:
                        methods.setValue(key, value == true)
                        break;
                    default:
                        if (_col.Column) {
                            methods.setValue(key, _col.Form.ComponentType === ComponentType.selectMultiple ? value.split(",") : value)
                        } else {
                            methods.setValue(key, value)
                        }
                        break;
                }
            } else methods.setValue(key, value)
        }
        setCols(_setCols)
        setRels(data.rels)
    }, [])

    useEffect(() => {
        _rels.forEach((_rel) => {
            const _dataPKController = new DataController(_rel.TablePK)
            if (_rel.TablePK === module) {
                _dataPKController.aggregateList({ page: 1, size: 20, searchRaw: _rel.Query?.length ? _rel.Query : "*", filter: `APPLY exists(@ParentId) AS __exist FILTER (@__exist == 0)` }).then((res) => {
                    methodOptions.setValue(`${_rel.Column}_Options`, res?.data ?? [])
                })
            } else {
                _dataPKController.getListSimple({ page: 1, size: 20, query: _rel.Query, returns: ["Id", "Name"] }).then((res) => {
                    methodOptions.setValue(`${_rel.Column}_Options`, res?.data ?? [])
                })
            }
        })
    }, [_rels])

    return <form className="col" style={{ flex: 1, width: '100%', height: '100%' }}>
        <div className="col" style={{ flex: 1, width: '100%', height: '100%', padding: '2.4rem', gap: '2rem', overflow: 'hidden auto' }}>
            {_cols.map((e) => {
                if ([FEDataType.DATE, FEDataType.DATETIME, FEDataType.NUMBER, FEDataType.MONEY].includes(e.DataType) && !e.Form?.Options?.length) {
                    return <RangeForm
                        key={e.Id}
                        className={'row'}
                        labelStyle={{ width: "16rem" }}
                        type={e.DataType === FEDataType.DATE ? 'date' : e.DataType === FEDataType.DATETIME ? 'date-time' : e.DataType === FEDataType.MONEY ? 'money' : 'number'}
                        startName={`${e.Name}_min`}
                        endName={`${e.Name}_max`}
                        control={methods.control}
                        label={e.Setting.Title}
                        errors={methods.formState.errors}
                        getValues={methods.getValues}
                        style={{ order: e.Form.Sort }}
                    />
                } else return <RenderComponentByType label={e.Setting.Title} key={e.Id} fieldItem={{ ...e, Form: { ...e.Form, Required: false } }} methods={methods} style={{ order: e.Form.Sort }} className={'row'} labelStyle={{ width: "16rem" }} />
            })}
            {_rels.map((_rel, _) => {
                switch (_rel.Form.ComponentType) {
                    case ComponentType.selectMultiple:
                        return <SelectMultipleForm
                            key={_rel.Id}
                            className={'row'}
                            labelStyle={{ width: "16rem" }}
                            control={methods.control}
                            errors={methods.clearErrors}
                            name={_rel.Column}
                            label={_rel.Form.Label ?? _rel.Column}
                            style={{ order: _rel.Form.Sort }}
                            options={(methodOptions.watch(`${_rel.Column}_Options`) ?? []).map(e => {
                                return { id: e.Id, name: e.Name }
                            })}
                        />
                    default:
                        return <Select1Form
                            key={_rel.Id}
                            className={'row'}
                            labelStyle={{ width: "16rem" }}
                            control={methods.control}
                            errors={methods.clearErrors}
                            name={_rel.Column}
                            label={_rel.Form.Label ?? _rel.Column}
                            style={{ order: _rel.Form.Sort }}
                            options={(methodOptions.watch(`${_rel.Column}_Options`) ?? []).map(e => {
                                return { id: e.Id, name: e.Name }
                            })}
                        />
                }
            })}
        </div>
        <div className="row popup-footer">
            <button type="button" className="row button-grey" onClick={() => {
                _cols.forEach(c => {
                    methods.setValue(c.Name, null)
                })
            }}>
                <Text className="button-text-3">Reset</Text>
            </button>
            <button type="button" className="row button-primary" onClick={methods.handleSubmit(onSubmit)}>
                <Text className="button-text-3">Apply</Text>
            </button>
        </div>
    </form>
})

export default PopupFormFilter