import { OptionsItem } from "wini-web-components"

export enum DataType {
    TAG = 'TAG',
    NUMERIC = "NUMERIC",
    TEXT = "TEXT",
}

export enum ToolType {
    Dashboard = "Dashboard",
    Manager = "Manager",
    Landing = "Landing",
    Report = "Report",
}

export interface TableItem {
    Id: string,
    Name: string,
    DateCreated: number,
    Description: string,
}

export interface MenuItem {
    Id: string,
    Name: string,
    TableId?: string,
    PageId: string
    Icon: string,
    ParentId: string,
    DateCreated: number,
    Sort: number,
    Description: string,
}

export interface RelativeItem {
    Id: string,
    TablePK: string,
    TableFK: string,
    Column: string,
    Query?: string,
    DateCreated: number,
    Shortcut?: string | { Title?: string, Sort?: number, IsHidden?: boolean },
    Setting?: string | { Width?: string | number, Title?: string, IsHidden?: boolean, Sort?: number },
    Form?: string | { Label: string, Placeholder?: string, ComponentType?: boolean, Sort?: number, Required?: boolean }
}

export enum FEDataType {
    GID = "GID",
    STRING = "STRING",
    NUMBER = "NUMBER",
    BOOLEAN = "BOOLEAN",
    DATE = "DATE",
    DATETIME = "DATETIME",
    MONEY = "MONEY",
    PASSWORD = "PASSWORD",
    LIST = "LIST",
    HTML = "HTML",
    FILE = "FILE",
}

export enum ValidateType {
    // required = 1,
    email = 2,
    minLength = 3,
    maxLength = 4,
    number = 5,
    phone = 6,
    // date = 7,
    // dateTime = 8,
    // earliestDate = 9,
    // latestDate = 10,
    // earliestTime = 11,
    // latestTime = 12,
    equality = 13,
    greaterThan = 14,
    greaterThanOrEqualTo = 15,
    lessThanOrEqualTo = 16,
    lessThan = 17,
    odd = 18,
    even = 19,
    async = 20
}

export interface ValidateItem {
    type: number,
    /** error message */
    message?: string,
    /** use when validate by compare with specific value, or validate async await with value is url*/
    value?: number | string,
}

export interface ColumnItem {
    Id?: string,
    Name: string,
    TableName?: string,
    DataType: FEDataType,
    DateCreated: number,
    Query?: string,
    Setting?: string | { Width?: string | number, Title?: string, IsHidden?: boolean, Description?: string, Sort?: number },
    Form?: string | { Sort?: number, ComponentType?: ComponentType, Min?: number, Max?: number, DefaultValue?: string | number, Label?: string, Placeholder?: string, AcceptFiles?: string, ReadOnly?: boolean, Disabled?: boolean, Required?: boolean, Validate?: Array<ValidateItem>, Options: Array<OptionsItem> }
}

export enum ComponentType {
    text = 'Text',
    img = 'Image',
    checkbox = 'Checkbox',
    switch = 'Switch',
    textField = 'Textfield',
    button = 'Button',
    calendar = 'Calendar',
    datePicker = 'DatePicker',
    radio = 'Radio',
    select1 = 'Select1',
    selectMultiple = 'SelectMultiple',
    textArea = 'Textarea',
    table = 'Table',
    dateTimePicker = 'DateTimePicker',
    navLink = 'Navlink',
    rate = 'Rate',
    upload = 'Upload',
    importMultipleFile = 'ImportMultiple',
    ckEditor = 'CkEditor',
    range = 'Range',
    container = "Container",
    chart = "Chart",
    form = "Form",
    card = "Card",
    report = "Report",
    none = "none"
}

export enum DesignTokenType {
    group = "group",
    color = "color",
    string = "string",
}

export interface DesignTokenItem {
    Id: string,
    Name: string,
    DateCreated: number,
    ParentId: string,
    Type: DesignTokenType, // type === group => parentId & value = undefined
    Sort: number,
    Value: string | { darkMode: string, lightMode: string },
}