import styles from './view.module.css'
import { forwardRef, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { closePopup, Switch, Text, TextField, Winicon } from "wini-web-components"
import { TableController } from '../tbController'
import { DataController } from '../dataController'
import { useSelector } from 'react-redux'
import { RoleType } from '../../da/configApi'

const DrawerSettingTableManager = forwardRef(function DrawerSettingTableManager(data, ref) {
    const _colController = new TableController("column")
    const _relController = new TableController("rel")
    const [cols, setCols] = useState([])
    const [rels, setRels] = useState([])
    const [actions, setActions] = useState([])
    const [toggleCol, setToggleCol] = useState(true)
    const [toggleAction, setToggleAction] = useState(true)
    const [selectedId, setSelectedId] = useState(data.cols.find(e => e.Name === "Name").Id)

    const onMouseDown = (ev) => {
        ev.preventDefault()
        const _colTile = ev.target.closest(`.row[class*="setting-column-tile"]`)
        const _tmp = [..._colTile.parentElement.children].sort((a, b) => parseInt(window.getComputedStyle(a).order ?? 0) - parseInt(window.getComputedStyle(b).order ?? 0))
        function onDrag(event) {
            if (_colTile) {
                _colTile.style.display = "none"
                let _demo = document.body.querySelector(`div[class*="demo-sort"]`)
                if (!_demo) {
                    _demo = document.createElement("div")
                    _demo.className = styles['demo-sort']
                }
                const _children = _tmp.filter(e => e.id !== _colTile.id && !e.classList.contains(styles['demo-sort']))
                let _order = 0
                let _distance = 0
                let closestHTML = [..._children].sort((aHTML, bHTML) => {
                    let aRect = aHTML.getBoundingClientRect()
                    let bRect = bHTML.getBoundingClientRect()
                    let a_center_oy = Math.abs(event.pageY - (aRect.y + aRect.height / 2))
                    let b_center_oy = Math.abs(event.pageY - (bRect.y + bRect.height / 2))
                    return a_center_oy - b_center_oy
                })[0]
                if (closestHTML) {
                    let htmlRect = closestHTML.getBoundingClientRect()
                    _order = parseInt(window.getComputedStyle(closestHTML).order)
                    _distance = event.pageY - (htmlRect.y + htmlRect.height / 2)
                    if (_distance < 0 && closestHTML.id !== _children[0].id) _order--
                } else _order = _children.length - 1
                _demo.style.order = _order
                _colTile.style.order = _order
                if (_demo.parentElement !== _colTile.parentElement) _colTile.parentElement.appendChild(_demo)
            }
        }
        document.body.addEventListener('mousemove', onDrag)
        document.body.onmouseup = () => {
            if (_colTile) {
                document.body.querySelector(`div[class*="demo-sort"]`)?.remove()
                _colTile.style.removeProperty("display")
                const _childrenId = [..._colTile.parentElement.children].sort((a, b) => parseInt(window.getComputedStyle(a).order ?? 0) - parseInt(window.getComputedStyle(b).order ?? 0)).map(e => e.id)
                const _newCols = cols.map(e => {
                    let _index = _childrenId.findIndex(id => e.Id === id)
                    return { ...e, Setting: { ...e.Setting, Sort: _index } }
                })
                const _newRels = rels.map(e => {
                    let _index = _childrenId.findIndex(id => e.Id === id)
                    return { ...e, Setting: { ...e.Setting, Sort: _index } }
                })
                setCols(_newCols)
                setRels(_newRels)
                _colController.edit(_newCols.map(e => { return { ...e, Setting: JSON.stringify(e.Setting), Form: JSON.stringify(e.Form) } }))
                _relController.edit(_newRels.map(e => { return { ...e, Setting: JSON.stringify(e.Setting), Form: JSON.stringify(e.Form) } }))
                data.onChange({ newCols: _newCols, newRels: _newRels })
            }
            document.body.removeEventListener('mousemove', onDrag)
            document.body.onmouseup = undefined
        }
    }

    const updateColumn = (item) => {
        _colController.edit([{ ...item, Setting: JSON.stringify(item.Setting), Form: JSON.stringify(item.Form) }])
        const _newCols = cols.map(e => {
            if (e.Id === item.Id) return item
            else return e
        })
        setCols(_newCols)
        data.onChange({ newCols: _newCols })
    }

    const updateRel = (item) => {
        _relController.edit([{ ...item, Setting: JSON.stringify(item.Setting), Form: JSON.stringify(item.Form) }])
        const _newRels = rels.map(e => {
            if (e.Id === item.Id) return item
            else return e
        })
        setRels(_newRels)
        data.onChange({ newRels: _newRels })
    }

    const updateAction = (item) => {
        _relController.edit([{ ...item, Shortcut: JSON.stringify(item.Shortcut) }])
        const _newActions = actions.map(e => {
            if (e.Id === item.Id) return item
            else return e
        })
        setActions(_newActions)
        data.onChange({ newActions: _newActions })
    }

    useEffect(() => {
        setCols(data.cols.sort((a, b) => a.Setting.Sort - b.Setting.Sort))
        setRels(data.rels)
        setActions(data.actions)
    }, [])

    return <div className='col' style={{ width: '32rem', backgroundColor: '#fff', flex: 1 }}>
        <div className='row popup-header' style={{ padding: '1.2rem 1.2rem 1.2rem 2.4rem' }}>
            <Text className='heading-8' style={{ flex: 1 }}>Setting</Text>
            <button type='button' className='row icon-button28' onClick={() => { closePopup(ref) }}>
                <Winicon src={"fill/user interface/e-remove"} />
            </button>
        </div>
        <div className='col' style={{ flex: 1, overflow: "hidden auto", padding: '0.8rem 0', gap: '0.4rem' }}>
            <div className='row' style={{ gap: '1.2rem', padding: '0.4rem 1.6rem' }}>
                <button type='button' className='row icon-button20' onClick={() => { setToggleCol(!toggleCol) }}>
                    <Winicon src={`fill/arrows/triangle-${toggleCol ? "down" : "right"}`} size={"1.4rem"} />
                </button>
                <Text className='heading-8' style={{ flex: 1 }}>Column</Text>
            </div>
            <div className='col' style={{ display: toggleCol ? 'flex' : 'none' }}>
                {cols.filter(e => e.Name !== "Id").map((item) => {
                    return <div key={item.Id} id={item.Id} className={`row ${styles['setting-column-tile']} ${item.Id === selectedId ? styles['selected'] : ""}`} style={{ order: item.Setting.Sort }} onClick={() => { setSelectedId(item.Id) }}>
                        <div onMouseDown={onMouseDown} className='row icon-button24' style={item.Name === "Name" ? { pointerEvents: 'none' } : undefined}>
                            <Winicon src={"fill/development/apps"} size={"1.2rem"} color='#87909e' />
                        </div>
                        <TextField
                            className='placeholder-2'
                            defaultValue={item.Setting?.Title ?? item.Name}
                            onComplete={(ev) => ev.target.blur()}
                            onBlur={(ev) => {
                                const _newTitle = ev.target.value.trim()
                                if (_newTitle !== item.Setting?.Title) {
                                    updateColumn({ ...item, Setting: { ...item.Setting, Title: _newTitle.length ? _newTitle : item.Name } })
                                }
                            }}
                        />
                        <Switch disabled={item.Name === "Name"} value={!item.Setting?.IsHidden} onChange={(v) => { updateColumn({ ...item, Setting: { ...item.Setting, IsHidden: !v } }) }} />
                    </div>
                })}
                {rels.map((item) => {
                    return <div key={item.Id} id={item.Id} className={`row ${styles['setting-column-tile']} ${item.Id === selectedId ? styles['selected'] : ""}`} style={{ order: item.Setting.Sort }} onClick={() => { setSelectedId(item.Id) }}>
                        <div onMouseDown={onMouseDown} className='row icon-button24'>
                            <Winicon src={"fill/development/apps"} size={"1.2rem"} color='#87909e' />
                        </div>
                        <TextField
                            className='placeholder-2'
                            defaultValue={item.Setting?.Title ?? item.Column}
                            onBlur={(ev) => {
                                const _newTitle = ev.target.value.trim()
                                if (_newTitle !== item.Setting?.Title) {
                                    updateRel({ ...item, Setting: { ...item.Setting, Title: _newTitle.length ? _newTitle : item.Column } })
                                }
                            }}
                        />
                        <Switch value={!item.Setting?.IsHidden} onChange={(v) => { updateRel({ ...item, Setting: { ...item.Setting, IsHidden: !v } }) }} />
                    </div>
                })}
            </div>
            <div className='row' style={{ gap: '1.2rem', padding: '0.4rem 1.6rem' }}>
                <button type='button' className='row icon-button20' onClick={() => { setToggleAction(!toggleAction) }}>
                    <Winicon src={`fill/arrows/triangle-${toggleAction ? "down" : "right"}`} size={"1.4rem"} />
                </button>
                <Text className='heading-8' style={{ flex: 1 }}>Action</Text>
            </div>
            <div className='col' style={{ display: toggleAction ? 'flex' : 'none' }}>
                {actions.map((item) => {
                    return <div key={item.Id} className={`row ${styles['setting-column-tile']}`}>
                        <button type='button' className='row icon-button24'>
                            <Winicon src={"fill/development/apps"} size={"1.2rem"} color='#87909e' />
                        </button>
                        <TextField
                            className='placeholder-2'
                            defaultValue={item.Shortcut?.Title ?? item.TableFK}
                            onBlur={(ev) => {
                                const _newTitle = ev.target.value.trim()
                                if (_newTitle !== item.Shortcut?.Title) {
                                    updateAction({ ...item, Shortcut: { ...item.Shortcut, Title: _newTitle.length ? _newTitle : item.TableFK } })
                                }
                            }}
                        />
                        <Switch value={item.Shortcut?.IsHidden === false} onChange={(v) => { updateAction({ ...item, Shortcut: { ...item.Shortcut, IsHidden: !v } }) }} />
                    </div>
                })}
            </div>
        </div>
    </div>
})

const PopupActions = forwardRef(function PopupActions({ item, actions = [], onEdit = () => { }, onDelete = () => { }, onSelectAction = () => { } }, ref) {
    const _role = useSelector((store) => store.role.data)
    const _user = useSelector((store) => store.customer.data)
    const methods = useForm({ shouldFocusError: false })

    useEffect(() => {
        if (actions?.length) {
            actions.forEach((_rel) => {
                const _relDataCotroller = new DataController(_rel.TableFK)
                _relDataCotroller.getListSimple({
                    page: 1,
                    size: 1,
                    query: `@${_rel.Column}:{${item.Id}*}`,
                    returns: ["Id"]
                }).then((res) => {
                    if (res.code === 200) methods.setValue(_rel.Id, res.totalCount)
                })
            })
        }
    }, [actions])

    return <div className='col popup-actions' style={{ borderRadius: '0.8rem', minWidth: '16rem' }}>
        {_role?.ListActive?.includes(RoleType.edit) && <button type='button' className='row' onClick={() => {
            closePopup(ref)
            onEdit()
        }}>
            <Winicon src={"fill/user interface/edit"} size={"1.6rem"} />
            <Text className='button-text-3'>Edit</Text>
        </button>}
        {actions.map(e => {
            return <button key={e.Id} type='button' className='row' onClick={() => { onSelectAction(e) }}>
                <div style={{ width: '1.6rem' }} />
                <Text className='button-text-3'>{e.Shortcut.Title ?? e.TableFK}({methods.watch(e.Id)})</Text>
            </button>
        })}
        {_role?.ListActive?.includes(RoleType.delete) && _role.Id !== item.Id && _user.Id !== item.Id && <button type='button' className='row' onClick={() => {
            closePopup(ref)
            onDelete()
        }}>
            <Winicon src={"fill/user interface/trash-can"} size={"1.6rem"} />
            <Text className='button-text-3'>Delete</Text>
        </button>}
    </div>
})

export { DrawerSettingTableManager, PopupActions }