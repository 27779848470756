import { Dispatch, PayloadAction, UnknownAction, createSlice } from '@reduxjs/toolkit'
import { UserItem } from './da'
import { BaseDA } from '../../da/baseDA'
import ConfigApi from '../../da/configApi'
import { Ultis } from '../../Utils'

interface CustomerSimpleResponse {
    data?: UserItem,
    onLoading?: boolean,
    type?: string
}

const initState: CustomerSimpleResponse = {
    data: undefined,
    onLoading: false
}

export const customerSlice = createSlice({
    name: 'customer',
    initialState: initState,
    reducers: {
        handleActions: (state, action: PayloadAction<any>) => {
            switch (action.payload.type) {
                case 'GETINFOR':
                    state.data = action.payload.data
                    break;
                default:
                    break;
            }
            state.onLoading = false
        },
        onFetching: (state) => {
            state.onLoading = true
        },
    },
})

const { handleActions, onFetching } = customerSlice.actions

export default customerSlice.reducer

export class CustomerActions {
    static getInfor = async (dispatch: Dispatch<UnknownAction>) => {
        dispatch(onFetching())
        const res = await BaseDA.get(ConfigApi.url + 'data/getInfo', {
            headers: {
                pid: ConfigApi.pid,
                module: 'User'
            },
        })
        if (res.code === 200) {
            dispatch(handleActions({
                type: 'GETINFOR',
                data: res.data,
            }))
        }
    }

    static login = async (props: { username: string, password: string }) => {
        const res = await BaseDA.post(ConfigApi.url + 'data/ktx/login', {
            headers: {
                pid: ConfigApi.pid,
                module: 'User'
            },
            body: props
        })
        return res
    }
}